import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Box, Button, ButtonGroup } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

export default function AppHeader() {
  const navigate = useNavigate();

  return (
    <AppBar position="fixed" sx={{height: { md: 80 }, backgroundColor: "white"}}>
      <Container maxWidth="xl">
        <Toolbar sx={{height: 80}}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              // letterSpacing: '.3rem',
              color: 'black',
              textDecoration: 'none',
            }}
          >
            JIN YUBIN的美食指南
          </Typography>

          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'black',
              textDecoration: 'none',
            }}
          >
            yubin
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <ButtonGroup variant="outlined" aria-label="outlined button group">
          <Button variant='text' size='large' sx={{color: 'black'}} component={Link} to="/foodlist">食物清单</Button>
          <Button variant='text' size='large' sx={{color: 'black'}} component={Link} to="/about">关于我</Button>
      </ButtonGroup>
        </Toolbar>
      </Container>
    </AppBar>
  );
}