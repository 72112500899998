import { BrowserRouter, HashRouter } from 'react-router-dom';
import './App.css';
import Router from './routes';
import AppBody from './views/AppBody';
import AppFooter from './views/AppFooter';
import AppHeader from './views/AppHeader';
import { Box, createTheme, ThemeProvider } from '@mui/material';

const THEME = createTheme({
  typography: {
  //  "fontFamily": `Comic Sans MS, Comic Sans, Chalkboard, ChalkboardSE-Regular, sans-serif`,
   "fontFamily": 'Noto Serif SC',
   "fontSize": 14,
   "fontWeightLight": 300,
   "fontWeightRegular": 400,
   "fontWeightMedium": 500,
   body2: {
    lineHeight: 2
   }
  },
  spacing: (2),
});

function App() {
  return (
    // <BrowserRouter>
    //   <ThemeProvider theme={THEME}>
    //     <Router /> 
    //   </ThemeProvider>
    // </BrowserRouter>
    <HashRouter>
      <ThemeProvider theme={THEME}>
        <Router /> 
      </ThemeProvider>
    </HashRouter>
  );
}

export default App;
