import { Alert, Box, ButtonBase, Card, CardActions, CardContent, CardMedia, Collapse, Container, Grid, IconButton, Link, Rating, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material"; 
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import useMediaQuery from '@mui/material/useMediaQuery';
// portrait
import portrait_momonta from "../assets/portrait/portrait_momenta.jpg";
// logo
import bilibili_logo from "../assets/logo/bilibili.svg";
import blog_logo from "../assets/logo/blog.svg";
import email_logo from "../assets/logo/email.svg";
import github_logo from "../assets/logo/github.svg";
import twitter_logo from "../assets/logo/twitter.svg";
// images
import lemon_cake from "../assets/food/lemon_cake.jpg";
import xinhuaxi_fqhn from "../assets/food/xinhuaxi_fqhn.jpg"
import bbq from "../assets/food/BBQ.jpeg";
import foie_gras from "../assets/food/foie_gras.jpeg";
import kebab from "../assets/food/kebab.jpeg";
import passion_fruit from "../assets/food/passion_fruit.jpeg"
import pesto_pasta from "../assets/food/pesto_pasta.jpeg";
import pizza from "../assets/food/pizza.jpeg";
import wine from "../assets/food/wine.jpeg";
// china wheaten food
import dapaimian from "../assets/food/mianshi/dapaimian.jpg";
import fanqiefeiniumifen from "../assets/food/mianshi/fanqiefeiniumifen.jpg";
import fanqiehuangniumifen from "../assets/food/mianshi/fanqiehuangniumifen.jpg";
import huntun from "../assets/food/mianshi/huntun.jpg";
import jiucaibaozi from "../assets/food/mianshi/jiucaibaozi.jpg";
import niuzafen from "../assets/food/mianshi/niuroumifen.jpg";
import saozimian from "../assets/food/mianshi/saozimian.jpg";
import xunyoumian from "../assets/food/mianshi/xunyoumian.jpg";
import yanglingzhanshuimian from "../assets/food/mianshi/yanglingzhanshuimian.jpg";
import yangroumian from "../assets/food/mianshi/yangroumian.jpg";
import youpomian from "../assets/food/mianshi/youpomian.jpg";
import zhajiangmian from "../assets/food/mianshi/zhajiangmian.jpg";
import hongshaoniuroumian from "../assets/food/mianshi/hongshaoniuroumian.jpg";

// singapore food
import leshahaixianguo from "../assets/food/singpore/leshahaixianguo.jpg";
import shadiejirouchuan from "../assets/food/singpore/shadiejirouchuan.jpg";
import rougucha from "../assets/food/singpore/rougucha.jpg";
import yuwanmian from "../assets/food/singpore/yuwanmian.jpg";
import zacaifan from "../assets/food/singpore/zacaifan.jpg";
import ganchaohefen from "../assets/food/singpore/ganchaohefen.jpg";
import xingzhoumifen from "../assets/food/singpore/xingzhoumifen.jpg";
import yueguanghe from "../assets/food/singpore/yueguanghe.jpg";
// germany food
import cuipikaozhuzhou from "../assets/food/germany/cuipikaozhuzhou.jpg";
import deguoxiangchang from "../assets/food/germany/deguoxiangchang.jpg";
// japan food
import rishijiaozi from "../assets/food/japan/日式饺子.jpg";
import tungulamian from "../assets/food/japan/豚骨拉面.jpg";
// italy food
import luolejiangyidalimian from "../assets/food/italy/luolejiangyidalimian.jpeg";
import moguyidalimian from "../assets/food/italy/moguyidalimian.jpg";

// korea food
import hanshilamian from "../assets/food/korea/hanshilamian.jpg";
import biandang from "../assets/food/korea/biandang.jpg";
// india food
import biryani from "../assets/food/india/biryani.jpg";
import mashalanaicha from "../assets/food/india/mashalanaicha.jpg";
import yindufeibing from "../assets/food/india/yindufeibing.jpg";
// mexico food
import taco from "../assets/food/mexico/taco.jpg";

// western
import jiansanwenyu from "../assets/food/western/煎三文鱼.jpg";
import huilingdunniupai from "../assets/food/western/惠灵顿牛排.jpg";
import huafubingzaocantaocan from "../assets/food/western/华夫饼早餐套餐.jpg";
import jianniuyaorou from "../assets/food/western/煎牛腰肉.jpg";
import egan from "../assets/food/western/鹅肝.jpg";
// post
import post1 from "../assets/post/background1.png";

//css
import "../basic.css";
//icons
import CloseIcon from '@mui/icons-material/Close';

// others
import line from "../assets/svg/line.svg";
import { useState } from "react";

const ImageBackdrop = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: '#000',
  opacity: 0.5,
  transition: theme.transitions.create('opacity'),
}));

const ImageIconButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  display: 'block',
  padding: 0,
  borderRadius: 0,
  height: '40vh',
  [theme.breakpoints.down('md')]: {
      width: '100% !important',
      height: 400 
  },
  '&:hover': {
      zIndex: 1,
  },
  '&:hover .imageBackdrop': {
      opacity: 0.15,
  },
  '&:hover .imageMarked': {
      opacity: 0,
  },
  '&:hover .imageTitle': {
      border: '4px solid currentColor',
  },
  '& .imageTitle': {
      position: 'relative',
      padding: `${theme.spacing(2)} ${theme.spacing(4)} 14px`,
  },
  '& .imageMarked': {
      height: 3,
      width: 18,
      background: theme.palette.common.white,
      position: 'absolute',
      bottom: -2,
      left: 'calc(50% - 9px)',
      transition: theme.transitions.create('opacity'),
  },
}));

const gallery_list = [
  {
    url: lemon_cake,
    title: 'Lemon Cake',
    width: '30%',
  },
  {
    url: xinhuaxi_fqhn,
    title: 'Beef Rice Noodle',
    width: '40%',
  },
  {
    url: bbq,
    title: 'BBQ',
    width: '30%',
  },
  {
    url: foie_gras,
    title: 'Foie Gras',
    width: '40%',
  },
  {
    url: kebab,
    title: 'Kebab',
    width: '30%',
  },
  {
    url: passion_fruit,
    title: 'Passion Fruit',
    width: '30%',
  },
  {
    url: pesto_pasta,
    title: 'Pesto Pasta',
    width: '30%',
  },
  {
    url: pizza,
    title: 'Pizza',
    width: '40%',
  },
  {
    url: wine,
    title: 'Wine',
    width: '30%',
  },
];

const china_wheaten_food_list = [
  {
    url: dapaimian,
    title: "大排面",
    score: 3.5,
  },
  {
    url: fanqiefeiniumifen,
    title: "番茄肥牛米粉",
    score: 2,
  },
  {
    url: fanqiehuangniumifen,
    title: "番茄黄牛米粉",
    score: 4,
  },
  {
    url: huntun,
    title: "馄饨",
    score: 3.5,
  },
  {
    url: jiucaibaozi,
    title: "韭菜包子",
    score: 3,
  },
  {
    url: niuzafen,
    title: "牛杂粉",
    score: 3.5,
  },
  {
    url: saozimian,
    title: "臊子面",
    score: 3,
  },
  {
    url: xunyoumian,
    title: "蕈油面",
    score: 3,
  },
  {
    url: yanglingzhanshuimian,
    title: "杨凌蘸水面",
    score: 3,
  },
  {
    url: yangroumian,
    title: "羊肉面",
    score: 3.5,
  },
  {
    url: youpomian,
    title: "油泼面",
    score: 3.5,
  },
  {
    url: zhajiangmian,
    title: "炸酱面",
    score: 3,
  },
  {
    url: hongshaoniuroumian,
    title: "红烧牛肉面",
    score: 3.5,
  },
];

const singapore_food_list = [
  {
    url: leshahaixianguo,
    title: "叻沙海鲜锅",
    score: 3,
  },
  {
    url: shadiejirouchuan,
    title: "沙爹鸡肉串",
    score: 3,
  },
  {
    url: rougucha,
    title: "肉骨茶",
    score: 3,
  },
  {
    url: yuwanmian,
    title: "鱼丸面",
    score: 4,
  },
  {
    url: zacaifan,
    title: "杂菜饭",
    score: 3,
  },
  {
    url: ganchaohefen,
    title: "干炒河粉",
    score: 3,
  },
  {
    url: xingzhoumifen,
    title: "星洲米粉",
    score: 3.5,
  },
  {
    url: yueguanghe,
    title: "月光河",
    score: 3,
  },
];

const germany_food_list = [
  {
    url: cuipikaozhuzhou,
    title: "脆皮烤猪肘",
    score: 4,
  }, 
  {
    url: deguoxiangchang,
    title: "德国香肠",
    score: 3.5,
  }, 
];

const japan_food_list = [
  {
    url: rishijiaozi,
    title: "日式饺子",
    score: 2.5,
  }, 
  {
    url: tungulamian,
    title: "豚骨拉面",
    score: 3.5,
  }, 
];

const italy_food_list = [
  {
    url: luolejiangyidalimian,
    title: "罗勒酱意大利面",
    score: 3,
  }, 
  {
    url: moguyidalimian,
    title: "蘑菇意大利面",
    score: 2,
  }, 
  // {
  //   url: ,
  //   title: "",
  //   score: 3,
  // }, 
  // {
  //   url: ,
  //   title: "",
  //   score: 3,
  // }, 

];

const korea_food_list = [
  {
    url: hanshilamian,
    title: "韩式拉面",
    score: 3,
  }, 
  {
    url: biandang,
    title: "便当",
    score: 2.5,
  }, 
];

const india_food_list = [
  {
    url: biryani,
    title: "印度香饭",
    score: 3.5,
  }, 
  {
    url: yindufeibing,
    title: "印度飞饼",
    score: 3,
  }, 
  {
    url: mashalanaicha,
    title: "玛莎拉奶茶",
    score: 2.5,
  }, 
];

const mexico_food_list = [
  {
    url: taco,
    title: "塔可",
    score: 3.5,
  }, 
];

const western_food_list = [
  {
    url: huafubingzaocantaocan,
    title: "华夫饼早餐套餐",
    score: 3,
  }, 
  {
    url: huilingdunniupai,
    title: "惠灵顿牛排",
    score: 2.5,
  }, 
  {
    url: jiansanwenyu,
    title: "煎三文鱼",
    score: 2,
  }, 
  {
    url: jianniuyaorou,
    title: "煎牛腰肉",
    score: 3.5,
  }, 
  {
    url: egan,
    title: "鹅肝",
    score: 3,
  }, 
];

export default function AppBody() {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(true);
  return (
    <>
      <Container disableGutters maxWidth={false} sx={{ padding: 0, mt: 0 }}>

        {/* ****************************************************************** */}
        {/* 首页海报 */}
        {/* ****************************************************************** */}
        <Collapse in={open}>
          <Alert
            action={
              <IconButton
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mt: 1, mb: 2, display: {md: 'none'} }}
            severity="info"
          >
            点击美食名称即可查看详情^.^
          </Alert>
        </Collapse>
        {/* ****************************************************************** */}
        {/* 首页海报 */}
        {/* ****************************************************************** */}
        <Container 
          maxWidth={false} 
          sx={{
            display: 'flex', 
            backgroundImage: { md: `url(${post1})` },
            backgroundSize: { md: 'cover' }, 
            overflow: { md: 'hidden' }, 
            width: { md: '100%' },
            height: {md: 'calc(100vh - 80px)'},
            filter: 'brightness(49%)',       
            alignItems: "center",
          }}
        >
          <Typography variant="h3" sx={{pl: 30, display: {xs: "none", md: 'block'}, color: 'white', fontFamily: 'Noto Serif SC'}}>
            在这里，发现并感触食物的多样性
          </Typography>
        </Container>
        {/* ****************************************************************** */}
        {/* 中式面食 */}
        {/* ****************************************************************** */}
        <Container  sx={{mt: {xs: 20, md: 10}}}>
          <Typography variant="h3" align="center">
            中式面食
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'center', width: '100%', mt: 2}}>
            <i style={{
              display: "block",
              ml: "auto",
              mr: "auto",
              width: "104px",
              height: "6px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              mt: "10px",
              backgroundImage: `url(${line})`, 
              justifyContent: "center"
            }}></i>
          </Box>
          <ImageList sx={{ width: "100%", height: "auto", mt: 10 }} cols={matchesSM ? 3 : 5}>
            {china_wheaten_food_list.map((item) => (
              <ImageListItem key={item.url}>
                <img
                  srcSet={`${item.url}`}
                  src={`${item.url}?`}
                  // srcSet={`${item.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  // src={`${item.url}?w=248&fit=crop&auto=format`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={<Link href={"/#/fooddetail/"+item.title} underline="none">{item.title}</Link>}
                  subtitle={<Rating size="small" defaultValue={item.score} precision={0.5} readOnly />}
                  position="below"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Container>
        {/* ****************************************************************** */}
        {/* 新加坡 */}
        {/* ****************************************************************** */}
        <Container  sx={{mt: {xs: 20, md: 10}}}>
          <Typography variant="h3" align="center">
            新加坡美食
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'center', width: '100%', mt: 2}}>
            <i style={{
              display: "block",
              ml: "auto",
              mr: "auto",
              width: "104px",
              height: "6px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              mt: "10px",
              backgroundImage: `url(${line})`, 
              justifyContent: "center"
            }}></i>
          </Box>
          <ImageList sx={{ width: "100%", height: "auto", mt: 10 }} cols={matchesSM ? 3 : 5}>
            {singapore_food_list.map((item) => (
              <ImageListItem key={item.url}>
                <img
                  srcSet={`${item.url}`}
                  src={`${item.url}?`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={<Link href={"/#/fooddetail/"+item.title} underline="none">{item.title}</Link>}
                  subtitle={<Rating size="small" defaultValue={item.score} precision={0.5} readOnly />}
                  position="below"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Container>
        {/* ****************************************************************** */}
        {/* 德国 */}
        {/* ****************************************************************** */}
        <Container  sx={{mt: {xs: 20, md: 10}}}>
          <Typography variant="h3" align="center">
            德国菜
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'center', width: '100%', mt: 2}}>
            <i style={{
              display: "block",
              ml: "auto",
              mr: "auto",
              width: "104px",
              height: "6px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              mt: "10px",
              backgroundImage: `url(${line})`, 
              justifyContent: "center"
            }}></i>
          </Box>
          <ImageList sx={{ width: "100%", height: "auto", mt: 10 }} cols={matchesSM ? 3 : 5}>
            {germany_food_list.map((item) => (
              <ImageListItem key={item.url}>
                <img
                  srcSet={`${item.url}`}
                  src={`${item.url}?`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={<Link href={"/#/fooddetail/"+item.title} underline="none">{item.title}</Link>}
                  subtitle={<Rating size="small" defaultValue={item.score} precision={0.5} readOnly />}
                  position="below"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Container>
        {/* ****************************************************************** */}
        {/* 日本 */}
        {/* ****************************************************************** */}
        <Container  sx={{mt: {xs: 20, md: 10}}}>
          <Typography variant="h3" align="center">
            日本料理
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'center', width: '100%', mt: 2}}>
            <i style={{
              display: "block",
              ml: "auto",
              mr: "auto",
              width: "104px",
              height: "6px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              mt: "10px",
              backgroundImage: `url(${line})`, 
              justifyContent: "center"
            }}></i>
          </Box>
          <ImageList sx={{ width: "100%", height: "auto", mt: 10 }} cols={matchesSM ? 3 : 5}>
            {japan_food_list.map((item) => (
              <ImageListItem key={item.url}>
                <img
                  srcSet={`${item.url}`}
                  src={`${item.url}?`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={<Link href={"/#/fooddetail/"+item.title} underline="none">{item.title}</Link>}
                  subtitle={<Rating size="small" defaultValue={item.score} precision={0.5} readOnly />}
                  position="below"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Container>
        {/* ****************************************************************** */}
        {/* 韩国 */}
        {/* ****************************************************************** */}
        <Container  sx={{mt: {xs: 20, md: 10}}}>
          <Typography variant="h3" align="center">
            韩国料理
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'center', width: '100%', mt: 2}}>
            <i style={{
              display: "block",
              ml: "auto",
              mr: "auto",
              width: "104px",
              height: "6px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              mt: "10px",
              backgroundImage: `url(${line})`, 
              justifyContent: "center"
            }}></i>
          </Box>
          <ImageList sx={{ width: "100%", height: "auto", mt: 10 }} cols={matchesSM ? 3 : 5}>
            {korea_food_list.map((item) => (
              <ImageListItem key={item.url}>
                <img
                  srcSet={`${item.url}`}
                  src={`${item.url}?`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={<Link href={"/#/fooddetail/"+item.title} underline="none">{item.title}</Link>}
                  subtitle={<Rating size="small" defaultValue={item.score} precision={0.5} readOnly />}
                  position="below"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Container>
        {/* ****************************************************************** */}
        {/* 意大利 */}
        {/* ****************************************************************** */}
        <Container  sx={{mt: {xs: 20, md: 10}}}>
          <Typography variant="h3" align="center">
            意大利美食
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'center', width: '100%', mt: 2}}>
            <i style={{
              display: "block",
              ml: "auto",
              mr: "auto",
              width: "104px",
              height: "6px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              mt: "10px",
              backgroundImage: `url(${line})`, 
              justifyContent: "center"
            }}></i>
          </Box>
          <ImageList sx={{ width: "100%", height: "auto", mt: 10 }} cols={matchesSM ? 3 : 5}>
            {italy_food_list.map((item) => (
              <ImageListItem key={item.url}>
                <img
                  srcSet={`${item.url}`}
                  src={`${item.url}?`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={<Link href={"/#/fooddetail/"+item.title} underline="none">{item.title}</Link>}
                  subtitle={<Rating size="small" defaultValue={item.score} precision={0.5} readOnly />}
                  position="below"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Container>
        {/* ****************************************************************** */}
        {/* 印度 */}
        {/* ****************************************************************** */}
        <Container  sx={{mt: {xs: 20, md: 10}}}>
          <Typography variant="h3" align="center">
            印度风味
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'center', width: '100%', mt: 2}}>
            <i style={{
              display: "block",
              ml: "auto",
              mr: "auto",
              width: "104px",
              height: "6px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              mt: "10px",
              backgroundImage: `url(${line})`, 
              justifyContent: "center"
            }}></i>
          </Box>
          <ImageList sx={{ width: "100%", height: "auto", mt: 10 }} cols={matchesSM ? 3 : 5}>
            {india_food_list.map((item) => (
              <ImageListItem key={item.url}>
                <img
                  srcSet={`${item.url}`}
                  src={`${item.url}?`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={<Link href={"/#/fooddetail/"+item.title} underline="none">{item.title}</Link>}
                  subtitle={<Rating size="small" defaultValue={item.score} precision={0.5} readOnly />}
                  position="below"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Container>
        {/* ****************************************************************** */}
        {/* 墨西哥 */}
        {/* ****************************************************************** */}
        <Container  sx={{mt: {xs: 20, md: 10}}}>
          <Typography variant="h3" align="center">
            墨西哥风味
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'center', width: '100%', mt: 2}}>
            <i style={{
              display: "block",
              ml: "auto",
              mr: "auto",
              width: "104px",
              height: "6px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              mt: "10px",
              backgroundImage: `url(${line})`, 
              justifyContent: "center"
            }}></i>
          </Box>
          <ImageList sx={{ width: "100%", height: "auto", mt: 10 }} cols={matchesSM ? 3 : 5}>
            {mexico_food_list.map((item) => (
              <ImageListItem key={item.url}>
                <img
                  srcSet={`${item.url}`}
                  src={`${item.url}?`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={<Link href={"/#/fooddetail/"+item.title} underline="none">{item.title}</Link>}
                  subtitle={<Rating size="small" defaultValue={item.score} precision={0.5} readOnly />}
                  position="below"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Container>
        {/* ****************************************************************** */}
        {/* 西式简餐 */}
        {/* ****************************************************************** */}
        <Container  sx={{mt: {xs: 20, md: 10}}}>
          <Typography variant="h3" align="center">
            西式简餐
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'center', width: '100%', mt: 2}}>
            <i style={{
              display: "block",
              ml: "auto",
              mr: "auto",
              width: "104px",
              height: "6px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              mt: "10px",
              backgroundImage: `url(${line})`, 
              justifyContent: "center"
            }}></i>
          </Box>
          <ImageList sx={{ width: "100%", height: "auto", mt: 10 }} cols={matchesSM ? 3 : 5}>
            {western_food_list.map((item) => (
              <ImageListItem key={item.url}>
                <img
                  srcSet={`${item.url}`}
                  src={`${item.url}?`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={<Link href={"/#/fooddetail/"+item.title} underline="none">{item.title}</Link>}
                  subtitle={<Rating size="small" defaultValue={item.score} precision={0.5} readOnly />}
                  position="below"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Container>
        {/* ****************************************************************** */}
        {/* 美食长廊 */}
        {/* ****************************************************************** */}
        <Container sx={{ mt: 8 }}>
          <Typography variant="h3" align="center">
            美食长廊
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'center', width: '100%', mt: 2}}>
            <i style={{
              display: "block",
              ml: "auto",
              mr: "auto",
              width: "104px",
              height: "6px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              mt: "10px",
              backgroundImage: `url(${line})`, 
              justifyContent: "center"
            }}></i>
          </Box>
          <Box sx={{ mt: 8,  display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
              {gallery_list.map((image) => (
                <ImageIconButton
                  key={image.title}
                  style={{
                    width: image.width,
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundImage: `url(${image.url})`,
                    }}
                  />
                  <ImageBackdrop className="imageBackdrop" />
                  <Box
                    sx={{
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'common.white',
                    }}
                  >
                    <Typography
                      component="h3"
                      variant="h6"
                      color="inherit"
                      className="imageTitle"
                    >
                      {image.title}
                      <div className="imageMarked" />
                    </Typography>
                  </Box>
                </ImageIconButton>
              ))}
          </Box>
        </Container>
      </Container>
    </>
  );
}