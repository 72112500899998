import { useRoutes } from "react-router-dom";
import AppBody from "./views/AppBody";
import HomeLayout from "./layouts/HomeLayout";
import AppFoodList from "./views/AppFoodList";
import AppFoodDetail from "./views/AppFoodDetail";
import AppAbout from "./views/AppAbout";

export default function Router() {
    const routes = useRoutes([
        {
            path: '/',
            element: <HomeLayout></HomeLayout>,
            children: [
              { element: <AppBody></AppBody>, index: true },
              { element: <AppFoodList></AppFoodList>, path: "/foodlist"},
              { element: <AppFoodDetail></AppFoodDetail>, path: "/fooddetail/:foodname"},
              { element: <AppAbout></AppAbout>, path: "/about"},
            ]
          },
    ]);
    return routes;
}