import { Box, Container, Link, Typography } from "@mui/material";

export default function AppAbout() {
    return (
        <>
            <Container sx={{mt: 10, minHeight: 'calc(100vh - 130px)'}}>
                <Typography variant="h3" align="center">关于我</Typography>
                <Typography variant="body2">
                    JIN YUBIN是一位知名的美食评论家，以其对意大利面食、苏式甜点、美式快餐的深入研究和独到见解而闻名。他的味蕾敏锐，对食物的热爱和专业知识使他在美食界赢得了高度评价，他的评论总是能精准地捕捉到每道菜肴的精髓。
                </Typography>
                <Typography variant="body2">
                    他对食物的热爱源自他对意大利面食的痴迷。他可以轻易地区分各种面条的口感，即使是微妙的差异也不能逃过他的味蕾。他的评论中充满了对意大利面食的热爱，从经典的千层面到现代的意面创新，他的笔触都能生动描绘出每一道面食的独特风味。
                </Typography>
                <Typography variant="body2">
                    然而，他的美食探索并未止步于意大利面食。他的味蕾同样被苏式甜点所吸引。他对甜点的热爱源于对糖果的无尽迷恋，他的评论中充满了对苏式甜点的深深喜爱。他的笔下，每一款甜点都仿佛变得生动起来，让人仿佛能够尝到那丰富的味道。
                </Typography>
                <Typography variant="body2">
                    美式快餐也是他的另一大热爱。他的评论中充满了对汉堡、炸鸡、薯条等快餐的热情。他的文字中，每一种快餐都仿佛拥有了生命，让人不禁想要去尝试。
                </Typography>
                <Box sx={{display: "flex", width: "100%", flexDirection: "row-reverse", color: '#1976d2'}}>
                    {/* <Link href="http://www.imdanteking.com" underline="none"><Typography variant="subtitle2" align="right">我的主页</Typography></Link> */}
                    email: imdanteking@gmail.com
                </Box>
            </Container>
        </>
    );
}