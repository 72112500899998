import { Box } from "@mui/material";
import AppHeader from "../views/AppHeader";
import AppFooter from "../views/AppFooter";
import { Outlet } from "react-router-dom";

export default function HomeLayout() {
    return (
        <>
            <Box sx={{ display: "block", height: {xs: "80px", md: "80px"}}}></Box>
            <AppHeader />
            <Outlet />
            <AppFooter />
        </>
    );
}