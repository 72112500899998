import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Box, Container, Typography } from '@mui/material';
import line from "../assets/svg/line.svg";
import "../basic.css";

const AppFoodDetail = () => {
  const { foodname } = useParams();
  const [markdownData, setMarkdownData] = useState('');

  useEffect(() => {
    console.log(foodname);
    import(`../assets/doc/${foodname}.md`)
      .then(({ default: markdown }) => {
        setMarkdownData(markdown);
        console.log("success");
      })
      .catch((error) => console.error('Error loading markdown file:', error));
  }, [foodname]);

  return (
    <>
      <Container sx={{mt: 10, minHeight: 'calc(100vh - 130px)'}}>
          <Typography variant="h3" align="center">
            {foodname}
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'center', width: '100%', mt: 2, mb: 10}}>
            <i style={{
              display: "block",
              ml: "auto",
              mr: "auto",
              width: "104px",
              height: "6px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              mt: "10px",
              backgroundImage: `url(${line})`, 
              justifyContent: "center"
            }}></i>
          </Box>
        <ReactMarkdown className='detail'>{markdownData}</ReactMarkdown>
      </Container>
    </>
  );
};

export default AppFoodDetail;
